<template>
  <v-container
    fluid
    fill-height
    class="pa-0 align-center justify-center"
    :style="$vuetify.breakpoint.mobile ? `background-color: white;` : ''"
  >
    <form
      :class="$vuetify.breakpoint.mobile ? `pa-8` : `elevation-3 pa-10 rounded-lg`"
    >
      <div class="d-flex align-center justify-center pt-4 pb-4">
        <img
          src="@/assets/Lavirra_logo_SVG.svg"
          height="60px"
          width="auto"
        >
      </div>
      <div v-if="!successCard">
        <v-row>
          <h5 class="mx-auto">
            Reset password
          </h5>
        </v-row>
        <v-text-field
          v-model="email"
          :error-messages="emailErrors"
          :label="$t('common|email')"
          required
          outlined
          dense
          class="mt-4"
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
        />
        <v-alert
          class="mt-2 mb-2"
          :value="!!error"
          type="error"
          border="bottom"
          transition="scale-transition"
        >
          {{ error }}
        </v-alert>
        <div class="d-flex justify-center align-center">
          <v-btn
            color="primary"
            class="mt-4"
            small
            min-width="200"
            @click="submit"
          >
            Reset password
          </v-btn>
        </div>
        <div
          class="d-flex justify-center align-center mt-5"
          style="font-size: 18px;"
        >
          <router-link to="/login">
            {{ $t('profile|log_in') }}
          </router-link>
        </div>
        <v-overlay
          absolute
          :value="loading"
        >
          <v-progress-circular
            :size="50"
            color="white"
            indeterminate
          />
        </v-overlay>
      </div>
      <div
        v-else
      >
        <v-card
          color="success"
        >
          <div
            style="margin: auto; width: 100%; padding: 10px;"
          >
            <div
              style="text-align: center;"
            >
              Password reset successfull.
            </div><br>
            <div
              style="text-align: center;"
            >
              Check your email and follow the instructions!
            </div>
            <div style="text-align: center;">
              <v-btn
                color="primary"
                class="mt-10 mb-3"
                small
                min-width="200"
                @click="redirect"
              >
                ok
              </v-btn>
            </div>
          </div>
        </v-card>
      </div>
    </form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
// import store from '@/store/index'

export default {
  mixins: [validationMixin],
  validations: {
    email: { required, email }
  },
  data () {
    return {
      email: null,
      loading: false,
      error: null,
      opacity: 1,
      successCard: false
    }
  },
  computed: {
    emailErrors () {
      const errors = []

      if (!this.$v.email.$dirty) return errors

      !this.$v.email.email && errors.push(this.$t('warning|valid_email'))
      !this.$v.email.required && errors.push(this.$t('warning|email_required'))

      return errors
    }
  },
  methods: {
    ...mapActions({
      setToken: 'auth/setToken',
      setAccount: 'account/setAccount'
    }),
    async submit () {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.loading = true
        try {
          const { data } = await this.$axios.put('/resetPasswordRequest', {
            email: this.email
          })
          if (data && data.success) {
            this.error = null
            this.successCard = true
          }
        } catch (e) {
          this.loading = false
          console.error(e, 'error')
          this.error = e
        } finally {
          this.loading = false
        }
      }
    },
    redirect () {
      let page = 'login'
      this.$router.push({ path: `/${page}` })
    }
  }
}
</script>

<style lang="scss" scoped>
  form {
    position: relative;
    width: 100%;
    max-width: 500px;
    background-color: rgb(255, 255, 255);
  }
</style>
